import Luna from "../assets/luna.png"
import Carlos from "../assets/carlos.png"
import Avery from "../assets/avery.png"
import Maxwell from "../assets/maxwell.png"
import Elenor from "../assets/eleanor.png"
import { Coach } from "../components"

export const coachs: Coach[] = [
  { id: 0, img: Luna, status: true, videoURL: "https://firebasestorage.googleapis.com/v0/b/life-buddy-ai.appspot.com/o/luna_540p.mp4?alt=media&token=85ef3ba6-1b23-44d0-ba5e-81d727e35d82", fullname: "Luna Gray", coach: "luna", position: "The Millennial Motivator" },
  { id: 1, img: Avery, status: true, videoURL: "https://firebasestorage.googleapis.com/v0/b/life-buddy-ai.appspot.com/o/avery_540p.mp4?alt=media&token=9b57f377-7063-41e7-b8e9-6239c13804a9", fullname: "Avery Jordan", coach: "avery", position: "The Transition Guide" },
  { id: 2, img: Maxwell, status: true, videoURL: "https://firebasestorage.googleapis.com/v0/b/life-buddy-ai.appspot.com/o/max_540p.mp4?alt=media&token=1720e77b-a521-4346-b40d-f9597c6d8f74", fullname: "Maxwell Sterling", coach: "maxwell", position: "The Corporate Climber's Guide" },
  { id: 3, img: Carlos, status: true, videoURL: "https://firebasestorage.googleapis.com/v0/b/life-buddy-ai.appspot.com/o/carlos_540p.mp4?alt=media&token=59ad3175-3f22-4e85-8aa4-e10550f76a84", fullname: "Carlos Mendoza", coach: "carlos", position: "The Parenting Pathfinder" },
  { id: 4, img: Elenor, status: true, videoURL: "https://firebasestorage.googleapis.com/v0/b/life-buddy-ai.appspot.com/o/eleanor_540p.mp4?alt=media&token=af2a5c2b-5756-4284-8608-d364461300f2", fullname: "Eleanor Brook", coach: "eleanor", position: "The Golden Years Guru"  },
]
