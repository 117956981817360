import { useForm } from "react-hook-form"
import { AdditionalHeader, Button, Form, InputWrapper, Page } from "../../components"
import { auth } from "../../config"
import { createUserWithEmailAndPassword } from "firebase/auth"
import { SignUpWithEmailFields } from "./types"
import { yupResolver } from "@hookform/resolvers/yup"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import { useContext } from "react"
import { RootContext, ContextValue } from "../Layout/Layout"
import { userController } from "../../api"
import { useSendPageView } from "../../hooks"

const schema = yup.object().shape({
  email: yup.string().email("The email field must be valid").required("The field required"),
  password: yup
    .string()
    .min(6, "Password should be at least 6 characters")
    .max(128, "Password must be less than 128 characters")
    .required("The field required"),
})

export const SignUpWithEmail = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpWithEmailFields>({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  })
  const { state, dispatch } = useContext(RootContext) as ContextValue
  const { signUpState: {name, age, coach, gender}} = state;

  useSendPageView({
    title: "SignUpWithEmail"
  })


  const user = {
    name: name ?? "",
    age: `${age}`,
    gender: gender ?? "male",
    coach: coach ?? ""
  }

  const signInWithEmail = async ({ email, password }: SignUpWithEmailFields) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password)
      const idToken = await result.user.getIdToken()
      dispatch({ type: "SET_REGISTRATION_METHOD", payload: "email" })
  
      await userController.saveUser(user, idToken);
      navigate("/success")
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Page>
      <AdditionalHeader title='Sign Up with Email' previousPath='/sign-up' />
      <Form onSubmit={handleSubmit(signInWithEmail)}>
        <InputWrapper<SignUpWithEmailFields>
          name='email'
          register={register}
          placeholder='Email'
          titleVariant='h5'
          title='Enter your email'
          errorMessage={errors.email?.message}
        />
        <InputWrapper<SignUpWithEmailFields>
          name='password'
          register={register}
          placeholder='Password'
          titleVariant='h5'
          title='Enter your password'
          type='password'
          errorMessage={errors.password?.message}
        />
        <Button variant='next' type='submit' disabled={!!Object.keys(errors).length}>
          Next
        </Button>
      </Form>
    </Page>
  )
}
