import styled from "styled-components"
import { ButtonSize, ButtonVariant } from "./types"

const sizes: Record<ButtonSize, string> = {
  small: "4px 12px",
  medium: "8px 12px",
  normal: "12px 16px",
  large: "16px",
}

export const ButtonS = styled.button<{
  $variant?: ButtonVariant | undefined
  $disabled: boolean
  $size: ButtonSize
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: ${({$size}) => sizes[$size]};
  border-radius: ${({ $variant }) => ($variant === "next" ? "25px" : "23px")};
  width: ${({ $variant }) => ($variant === "next" ? "100%" : "fit-content")};
  font-size: ${({ $variant }) => ($variant === "next" ? "17px" : "14px")};
  font-weight: 600;
  line-height: 22px;

  background-color: ${({ $variant }) =>
    $variant === "primary" || $variant === "next" ? "#4E84A6" : "#fc852b"};

  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
  opacity: ${({ $disabled }) => ($disabled ? "0.5" : "1")};
`
