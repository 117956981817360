import styled from "styled-components"
import { Link } from "react-router-dom"

export const VideoS = styled.video`
  width: 100%;
  border-radius: 15px;
`

export const VideoContainer = styled.div`
  height: 100%;
  max-width: 580px;
  display: flex !important;
  width: 100%;
  margin: auto;
  position: relative;
  border-radius: 15px;
`

export const LinkCustom = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  color: rgba(191, 96, 96, 1);
  justify-content: center;
  margin-bottom: 25px;
`
