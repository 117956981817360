import { ReactNode, FC } from "react"
import { WrapperS } from "./styles"

interface Props {
  children: ReactNode,
  gap?: string
}

export const Wrapper: FC<Props> = ({ children, gap }) => {
  return <WrapperS $gap={gap}>{children}</WrapperS>
}
