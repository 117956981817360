import { Action } from "../../types"
import { SignUpState } from "./types"

export const signUpState: SignUpState = {
  name: null,
  age: null,
  gender: null,
  coach: null,
  queryParams: ""
}

export const signUpReducer = (state: SignUpState, action: Action): SignUpState => {
  switch (action.type) {
    // case "SET_REGISTRATION_METHOD":
    //   localStorage.setItem("LB_REGISTRATION_METHOD", JSON.stringify(action.payload));
    //   return {
    //     ...state,
    //     registrationMethod: action.payload,
    //   }
    case "SET_USER_DATA":
      return {
        ...state,
        name: action.payload.name,
        age: action.payload.age,
        gender: action.payload.gender,
      }
    case "SET_COACH":
      return {
        ...state,
        coach: action.payload,
      }
    case "SET_QUERY_PARAMS":
      return {
        ...state,
        queryParams: action.payload,
      }
    default:
      return state
  }
}
