import { IIndent } from "../components";

export const privacyIndents: IIndent[] = [
  {
    id: 1,
    title: "1. What information do we collect?",
    description: "We collect the following types of personal information from you:",
    list: [
      {
        id: 1,
        text: "Information you provide to us directly, such as when you create an account, provide feedback, or contact us.",
      },
      {
        id: 2,
        text: "Information that you generate while using the App, such as your gameplay data, search history, and interaction with other users.",
      },
      {
        id: 3,
        text: "Information that you generate while using the App, such as your gameplay data, search history, and interaction with other users.",
      },
    ],
  },
  {
    id: 2,
    title: "2. How do we use your information?",
    description:
      "We use your information to provide you with the App and to improve your experience. This includes:",
    list: [
      {
        id: 1,
        text: "Providing and maintaining the App.",
      },
      {
        id: 2,
        text: "Providing you with personalized content and recommendations.",
      },
      {
        id: 3,
        text: "Communicating with you about the App and other products and services offered by the Developer.",
      },
      {
        id: 4,
        text: "Analyzing how you use the App to improve our products and services.",
      },
      {
        id: 5,
        text: "Detecting and preventing fraud and abuse.",
      },
    ],
  },
  {
    id: 3,
    title: "3. How do we share your information?",
    description:
      "We may share your information with third parties in the following limited circumstances:",
    list: [
      {
        id: 1,
        text: "With your consent. For example, we may share your information with third-party providers to help us provide you with personalized content or to help us analyze how you use the App.",
      },
      {
        id: 2,
        text: "To comply with the law. For example, we may share your information with law enforcement agencies if required to do so by law.",
      },
      {
        id: 3,
        text: "To protect our rights or property. For example, we may share your information with our lawyers if we are involved in a legal dispute.",
      },
    ],
  },
  {
    id: 4,
    title: "4. How do we protect your information?",
    description:
      "We take measures to protect your information from unauthorized access, use, disclosure, alteration, or destruction. These measures include:",
    list: [
      {
        id: 1,
        text: "Using industry-standard security measures, such as encryption and firewalls.",
      },
      {
        id: 2,
        text: "Limiting access to your information to authorized employees and contractors.",
      },
      {
        id: 3,
        text: "Limiting access to your information to authorized employees and contractors.",
      },
    ],
  },
  {
    id: 5,
    title: "5. Your choices",
    description: "You have the following choices about your information:",
    list: [
      {
        id: 1,
        text: "You have the following choices about your information:                        ",
      },
      {
        id: 2,
        text: "You can opt out of receiving marketing communications from us by clicking on the unsubscribe link in any marketing email you receive from us.",
      },
      {
        id: 3,
        text: "You can delete your account by contacting us at info@lifebuddy.ai",
      },
    ],
  },
  {
    id: 6,
    title: "6. Children's privacy",
    description:
      "The App is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and you believe that your child under the age of 13 has provided us with personal information, please contact us at info@lifebuddy.ai",
  },
  {
    id: 7,
    title: "7. International transfers",
    description:
      "Your information may be transferred to and stored in countries other than the country in which you reside. These countries may have different data protection laws than your country. When we transfer your information, we will take steps to protect it in accordance with this Policy.",
  },
  {
    id: 8,
    title: "8. Changes to this Policy",
    description:
      "We may update this Policy from time to time. We will notify you of any material changes by posting the updated Policy on the App or by sending you an email notification. You should review this Policy periodically to ensure that you are aware of any changes.",
  },
  {
    id: 9,
    title: "9. Contact Us",
    description:
      "If you have any questions about this Policy, please contact us at info@lifebuddy.ai",
  },
]
