import { Action } from "../../types"
import { signUpReducer } from "../signUpReducer/signUpReducer"
import { IntitialState } from "./types"

export const rootReducer = (state: IntitialState, action: Action) => {
  return {
    ...state,
    signUpState: signUpReducer(state.signUpState, action),
  }
}
