import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  Button,
  Page,
  Title,
  InputWrapper,
  Wrapper,
  Form,
  MobileHeader,
  Header,
} from "../../components"
import { GenderType, PersonalInfoFields } from "./types"
import { genders } from "../../data"
import { GenderChoiceWrapperS, GenderChoiceS, ErrorMessage, TextS } from "./styles"
import { RootContext, ContextValue } from "../Layout/Layout"
import { useSendPageView } from "../../hooks"
import { logEvent } from "firebase/analytics"
import { analytics } from "../../config"

const schema = yup.object().shape({
  name: yup
    .string()
    .max(100, "The field must contain a maximum of 100 characters")
    .required("The field required"),
  age: yup.string(),
})

export const PersonalInfo = () => {
  const [gender, setGender] = useState<GenderType | null>(null)
  const [showError, setShowError] = useState(false)
  const { dispatch } = useContext(RootContext) as ContextValue
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonalInfoFields>({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  })

  const onSubmit = (data: PersonalInfoFields) => {
    if (!gender) {
      setShowError(true)
      return
    }

    const userData = {
      name: data["name"],
      age: data["age"] || "",
      gender: gender,
    }

    logEvent(analytics, "save_profile", userData)
    dispatch({ type: "SET_USER_DATA", payload: userData })
    navigate("/success")
  }

  useSendPageView({
    title: "PersonalInfo",
  })

  const onInvalid = () => {
    setShowError(true)
  }

  return (
    <>
      <Header title='Tell us little bit about yourself' />
      <Page>
        <MobileHeader title='Tell us little bit about yourself' previousPath='/' />
        <Form onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <Wrapper>
            <Title variant='h4'>What is your gender?</Title>
            <GenderChoiceWrapperS>
              {genders.map((genderItem) => (
                <GenderChoiceS
                  key={genderItem.type}
                  onClick={() => setGender(genderItem.type)}
                  $isSelected={gender === genderItem.type}
                >
                  <TextS $isSelected={gender === genderItem.type}>{genderItem.name}</TextS>
                  <img
                    src={gender === genderItem.type ? genderItem.imgActive : genderItem.imgDisabled}
                    alt={genderItem.type}
                  />
                </GenderChoiceS>
              ))}
            </GenderChoiceWrapperS>
            {showError && !gender && <ErrorMessage>Gender not selected</ErrorMessage>}
          </Wrapper>
          <InputWrapper<PersonalInfoFields>
            name='name'
            title='How shall your coach call you?'
            titleVariant='h4'
            placeholder='Your nick or name'
            register={register}
            errorMessage={errors["name"]?.message}
          />
          <InputWrapper<PersonalInfoFields>
            name='age'
            title='For better tailored coaching, enter your age'
            titleVariant='h4'
            placeholder='Your age (optional)'
            register={register}
            errorMessage={
              errors["age"]?.message?.includes("age must be a `number`")
                ? "The field must contain numbers"
                : errors["age"]?.message
            }
          />
          <Button
            type='submit'
            variant='next'
            disabled={showError && (!!Object.keys(errors).length || !gender)}
          >
            Next
          </Button>
        </Form>
      </Page>
    </>
  )
}
