import { IIndent } from "../components"

export const howItWorksIndent: IIndent[] = [
  {
    id: 1,
    title: "Revolutionizing Life-Coaching with LifeBuddy.ai",
    description:
      "LifeBuddy.ai brings a revolutionary and transformative approach to life-coaching, combining the power of artificial intelligence with the expertise of human life-coaches, psychologists, and developmental specialists with artificial intelligence delivering a world-class life-coaching experience to anyone",
    list: [
      {
        id: 1,
        bold: "AI Coach Design and Personalization: ",
        text: "LifeBuddy.ai's AI life-coaches, modeled after successful human coaches, are powered by advanced language models trained in diverse coaching techniques, psychoanalysis, and behavioral psychology. Clients engage with their AI coach as they would with a human, allowing for personalized and evolving interactions.",
      },
      {
        id: 2,
        bold: "Coaching Tools and Techniques: ",
        text: "The program includes a variety of tools such as meditations, personality tests, role-playing exercises, progress tracking, and homework monitoring, ensuring a comprehensive coaching experience.",
      },
      {
        id: 3,
        bold: "Session Frequency and Duration: ",
        text: "Regular interaction is key. Sessions are recommended weekly, ideally with a 5-day interval for reflection and application. Each session lasts approximately 45 minutes, adjustable to client preferences. No need for advanced booking of sessions. Your next session is ready and accessible 5 days after completion of your previous session. In the meantime, you can have a chat with your AI life-coach and explore any topic you find interesting. ",
      },
      {
        id: 4,
        bold: "Feedback and Coach Customization: ",
        text: "Client feedback is vital in refining the AI coach's approach, enabling the customization of coaching style for optimal results. With time, clients are able to create and customize their own coach that meets their needs and is fully adapted to client’s preferred coaching style. ",
      },
      {
        id: 5,
        bold: "Confidentiality and Data Handling: ",
        text: "Ensuring client confidentiality is paramount. Session records are strictly confidential and accessible only to certified experts. Clients have control over their data sharing preferences.",
      },
      {
        id: 6,
        bold: "Coach Switching Policy: ",
        text: "Clients may switch coaches after 4 sessions to maintain rapport and continuity. All notes and records can be transferred to the new coach, with an option for a fresh start.",
      },
    ],
  },
]
