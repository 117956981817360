import { TextField } from "@mui/material"
import styled from "styled-components"

export const InputS = styled(TextField)<{ $isError?: boolean }>`
  width: 100%;
  overflow: hidden;
  border-radius: 12px;

  & :focus {
    border: 2px solid ${({ $isError }) => ($isError ? "#ea3225" : "rgba(242, 159, 141, 1)")} !important;
    border-radius: 12px;

    transition: 100ms ease-in-out;
  }

  input {
    background-color: white;
    border-radius: 12px;
  }

  div {
    border-radius: 12px;
  }

  label {
    font-size: 14px;
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    color: ${({ $isError }) => ($isError ? "#ea3225" : "#2d2e3d")} !important;
  }

  p {
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
  }

  div::before {
    border: none;
  }

  div::after {
    border: none;
  }

  div:hover::before {
    border: none !important;
  }
`
