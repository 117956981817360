import { Outlet, useLocation } from "react-router-dom"
import { Footer } from "../../components"
import { Dispatch, createContext, useEffect, useReducer, Suspense } from "react"
import { ContentS } from "./styles"
import { Action, IntitialState, intialState, rootReducer } from "../../store"
import { Nullabe } from "../../types"
import * as querystring from "node:querystring"

export type ContextValue = {
  state: IntitialState
  dispatch: Dispatch<Action>
}

export const RootContext = createContext<Nullabe<ContextValue>>(null)

export const Layout = () => {
  const { search } = useLocation()
  const [state, dispatch] = useReducer(rootReducer, intialState)
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    // Remove ? from the beginning of search
    const queryString = search.substring(1);
    dispatch({ type: "SET_QUERY_PARAMS", payload: queryString })
  }, [])

  return (
    <>
      <RootContext.Provider value={{ state, dispatch }}>
        <ContentS $pathname={pathname}>
          <Suspense fallback=''>
            <Outlet />
          </Suspense>
        </ContentS>
        <Footer />
      </RootContext.Provider>
    </>
  )
}
