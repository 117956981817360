import { User } from "./types";

class UserController {
  async saveUser(user: User, token: string) {
    await fetch("https://api.lifebuddy.ai/v1/user/", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
  }
}

export const userController = new UserController;

