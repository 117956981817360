import { IIndent } from "../components"

export const introductionIndents: IIndent[] = [
  {
    id: 1,
    title: "Unveiling LifeBuddy.ai: Your Personalized AI Life Coach",
    description:
    "Life can be a whirlwind. Between juggling work, relationships, and personal aspirations, it's easy to feel lost or stuck. That's where LifeBuddy.ai steps in. We're a revolutionary life-coaching app designed to empower you to reach your full potential."
  },
  {
    id: 2,
    title: "Transformative Coaching, Reimagined",
    description:
      "LifeBuddy.ai goes beyond traditional coaching methods. We seamlessly blend cutting-edge artificial intelligence with the wisdom of human experts. This dynamic duo provides you with a world-class coaching experience, tailored specifically to your needs.",
  },
  {
    id: 3,
    title: "Your AI Coach: Personalized Support, 24/7",
    description:
      "Imagine having a dedicated life coach available at your fingertips, whenever you need it. That's the power of LifeBuddy.ai. Our AI coaches are built on advanced language models trained by human experts in coaching techniques, psychoanalysis, and behavioral psychology. This allows for personalized interactions that evolve alongside your journey.",
  },
  {
    id: 4,
    title: "A Toolbox for Growth",
    description:
      "LifeBuddy.ai doesn't just offer insightful conversations. We equip you with a comprehensive toolkit for self-improvement. Our app features:",
      list: [
            {
                  id: 1,
                  bold: "Meditations: ",
                  text: "Find guided meditations to manage stress, boost focus, and achieve your goals."
            },
            {
                  id: 2,
                  bold: "Personality Tests: ",
                  text: "Gain valuable insights into your strengths and personality, allowing you to leverage them for success."
            },
            {
                  id: 3,
                  bold: "Role-playing Exercises: ",
                  text: "Practice real-life situations in a safe space, building confidence and communication skills."
            },
            {
                  id: 4,
                  bold: "Progress Tracking: ",
                  text: "Monitor your progress towards your goals and stay motivated on your path to self-discovery."
            },
            {
                  id: 5,
                  bold: "Homework Monitoring: ",
                  text: "Ensure you're putting your learnings into action and holding yourself accountable."
            },
            {
                  id: 5,
                  bold: "Flexibility at Your Fingertips",
                  text: ""
            }
      ],
      text: "Life is busy, and your coaching shouldn't be rigid. With LifeBuddy.ai, you have complete control over your sessions. Weekly interactions are recommended, with convenient 5-day intervals for reflection and application.  Each session is adjustable to your needs, and there's no need for advanced booking – your next session is always readily available when you are."
  },
  {
    id: 5,
    title: "Your Journey, Your Way",
    description:
      "We believe in putting you in the driver's seat. LifeBuddy.ai prioritizes your privacy and data security. All session records are strictly confidential and only accessible to authorized experts involved in your coaching journey. You also have complete control over what data you share.",
  },
  {
    id: 6,
    title: "Ready to Start Your Transformation?",
    description:
      "LifeBuddy.ai is more than just an app; it's your partner in growth. Download the app today and embark on a transformative journey towards a happier, more fulfilling life.",
  },
]
