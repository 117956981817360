import { FC } from "react"
import styled from "styled-components"

export interface IIndent {
  id: number
  title: string
  description: string
  list?: {
    id: number
    bold?: string
    text: string
  }[]
  text?: string
}

interface Props {
  indent: IIndent
  listType?: "decimal" | "disc" | "none"
}

const IndentS = styled.div<{ $listType: "decimal" | "disc" | "none" }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  line-height: 24px;  

  & span {
    font-weight: 600;
  }

  & ol {
    display: flex;
    flex-direction: column;
    list-style: outside ${({ $listType }) => $listType};
    padding-left: 10px;
    margin-left: 10px;
  }
`

export const Indent: FC<Props> = ({ indent, listType = "disc" }) => {
  return (
    <IndentS $listType={listType}>
      <span>{indent.title}</span>
      <p>{indent.description}</p>
      <ol>
        {indent.list &&
          indent.list.map((item) => {
            return (
              <li key={item.id}>
                {item.bold && <span>{listType === "none" && `${item.id}. `}{`${item.bold}`}</span>}
                {item.text}
              </li>
            )
          })}
      </ol>
      {indent.text && <p>{indent.text}</p>}
    </IndentS>
  )
}
