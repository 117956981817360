import { FC } from "react"
import { TitleProps } from "./types"
import { TitleS } from "./styles"

export const Title: FC<TitleProps> = ({ children, variant, color, styles }) => {
  return (
    <TitleS $color={color} $variant={variant} $styles={styles}>
      {children}
    </TitleS>
  )
}
