import styled from "styled-components"

export const DownloadS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  padding: 40px 20px;
  width: 1108px;
  max-width: 100%;
  border-bottom: 1px solid rgba(215, 215, 220, 1);
`
export const LineS = styled.div`
    height: 1px;
    width: 1108px;
    max-width: 100%;
    border-bottom: 1px solid rgba(215, 215, 220, 1);
`

export const FooterS = styled.footer`
  background-color: rgba(245, 245, 246, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
`

export const Copyright = styled.div`
  color: #606171;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
`
export const MenuLinks = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  & a {
    color: #38394E;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-decoration: none;
    padding: 3px 6px;
    cursor: pointer;
  }

  & div {
    width: 100%;
  }
`

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: auto;
  text-align: center;
  max-width: 100%;
  width: calc(540px + 150px);
  padding: 20px;
`

export const Info = styled.div`
  color: rgba(96, 97, 113, 1);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`

export const DownloadWith = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
`