import styled from "styled-components"
import { AuthCardVariant } from "./types"

export const AuthCardS = styled.div<{ $variant: AuthCardVariant }>`
  display: flex;
  align-items: center;
  background-color: ${({ $variant }) => ($variant === "filled" ? "white" : "transparent")};
  border: 1px solid ${({ $variant }) => ($variant === "outlined" ? "#14141b" : "transparent")};
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;

  & span {
    margin: 0 auto;
  }
`
