import { Link } from "react-router-dom"
import LogoImg from "../../../assets/logo.png"
import { Title } from "../index"
import { HeaderS, HeaderWrapS, HeaderContentS, Logo } from "./styles"
import { FC } from "react"
import { Props } from "./types"

export const Header : FC<Props> = ({ title }) => {
  return (
    <HeaderS>
      <HeaderWrapS>
        <HeaderContentS>
          <Link to='/' style={{ lineHeight: 0 }}>
            <Logo className='logo' src={LogoImg} alt='logo' />
          </Link>
          <Title variant='h4' color='#03143a'>
            { title }
          </Title>
        </HeaderContentS>
      </HeaderWrapS>
    </HeaderS>
  )
}
