import { Input, Wrapper } from ".."
import { Props } from "./types"
import { Title } from "../Title/Title"

export const InputWrapper = <T extends object>({
  register,
  errorMessage,
  placeholder,
  title,
  titleVariant,
  type,
  name
}: Props<T>) => {
  return (
    <Wrapper>
      <Title variant={titleVariant}>{title}</Title>
      <Input<T>
        name={name}
        placeholder={placeholder}
        register={register}
        isError={!!errorMessage}
        helperText={errorMessage}
        type={type}
      />
    </Wrapper>
  )
}
