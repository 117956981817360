import { Path } from "react-hook-form/dist/types"
import { Props } from "./types"
import { InputS } from "./styles"

export const Input = <T extends object>({
  placeholder,
  register,
  isError,
  helperText,
  type,
  name
}: Props<T>) => {
  return (
    <InputS
      label={placeholder}
      variant='filled'
      {...register(name as Path<T>)}
      $isError={isError}
      error={isError}
      helperText={helperText}
      InputProps={{
        type: type as string
      }}
    />
  )
}
