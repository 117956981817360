import styled from "styled-components"

const MAX_WIDTH_PAGES_750 = ["/terms-of-services", "/introduction", "/how-it-works", "/Q&A", "/privacy-policy", "/q&a"]
export const ContentS = styled.div<{ $pathname: string }>`
  background: #DCE6ED;
  color: #14141B;  
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  & div {
    max-width: ${({ $pathname }) => (MAX_WIDTH_PAGES_750.includes($pathname) ? "750px" : "")};
  }
`
