import styled from "styled-components"
import { TextVariant } from "./types"

export const textVariantStyles = {
  h4: "font-size: 24px; font-weight: 600; line-height: 32px;",
  h5: "font-size: 18px; font-weight: 600; line-height: 24px;",
}

export const textVariantMobileStyles = {
  h4: "font-size: 18px; font-weight: 600; line-height: 24px;",
  h5: "font-size: 16px; font-weight: 600; line-height: 22px;",
}

export const TitleS = styled.span<{ $variant: TextVariant; $color?: string | undefined; $styles?:  string | undefined;}>`
  color: ${({ $color }) => ($color ? $color : "#2d2e3e")};
  ${({ $variant }) => $variant && textVariantStyles[$variant]};
  ${({ $styles }) => $styles};
  
  @media (max-width: 576px) {
      ${({ $variant }) => $variant && textVariantMobileStyles[$variant]};
  }  
`
