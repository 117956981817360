import styled from "styled-components"

export const GenderChoiceWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 580px;
  width: 100%;
`

export const GenderChoiceS = styled.div<{ $isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 8px 15px;
  background-color: ${({ $isSelected }) => ($isSelected ? "white" : "#f5f5f6")};
  border-radius: 8px;
  transition: background-color 300ms ease-in-out;
  cursor: pointer;
  border: ${({ $isSelected }) => ($isSelected ? "2px solid rgba(242, 159, 141, 1)" : "none")};
`

export const ErrorMessage = styled.p`
  color: #d32f2f;
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  margin-left: 14px;
  position: absolute;
  bottom: -26px;
`

export const TextS = styled.span<{ $isSelected: boolean }>`
  font-size: 14px;
  font-weight: ${({ $isSelected }) => ($isSelected ? 600 : 400)};
  line-height: 22px;
  color: black;
`
