import { IIndent } from "../components"

export const faqIndents: IIndent[] = [
  {
    id: 1,
    title: "What is LifeBuddy.ai?",
    description:
      "LifeBuddy.ai is a revolutionary life-coaching app that combines the power of artificial intelligence (AI) with the expertise of human life coaches, psychologists, and developmental specialists. We offer a world-class life-coaching experience that's accessible to anyone.",
  },
  {
    id: 2,
    title: "How does the AI coach work?",
    description:
      "Our AI life coaches are modeled after successful human coaches and are powered by advanced language models trained in diverse coaching techniques, psychoanalysis, and behavioral psychology. You can interact with your AI coach just like you would with a human coach, allowing for personalized and evolving interactions.",
  },
  {
    id: 3,
    title: "What tools and techniques does LifeBuddy.ai use?",
    description:
      "LifeBuddy.ai offers a comprehensive coaching experience through a variety of tools, including:",
    list: [
      {
        id: 1,
        bold: "Meditations: ",
        text: "Find guided meditations to help with stress reduction, focus, and goal achievement.",
      },
      {
        id: 2,
        bold: "Personality Tests: ",
        text: "Gain valuable insights into your personality and how it affects your life.",
      },
      {
        id: 3,
        bold: "Role-playing Exercises: ",
        text: "Practice real-life situations in a safe and controlled environment.",
      },
      {
        id: 4,
        bold: "Progress Tracking: ",
        text: "Monitor your progress towards your goals and stay motivated.",
      },
      {
        id: 5,
        bold: "Homework Monitoring: ",
        text: "Ensure you're taking action and implementing the coaching strategies in your daily life.",
      },
    ],
  },
  {
    id: 4,
    title: "How often will I meet with my AI coach?",
    description:
      "For optimal results, we recommend weekly sessions with a 5-day interval between sessions. This allows you time to reflect on the coaching session and apply the strategies learned.",
  },
  {
    id: 5,
    title: "How long are the sessions?",
    description:
      "LifeBuddy.ai sessions are approximately 45 minutes long, but can be adjusted to your preferences. There's no need for advanced booking – your next session is automatically available 5 days after completing the previous one.",
  },
  {
    id: 6,
    title: "Can I customize my AI coach?",
    description:
      "Absolutely! Client feedback is vital for LifeBuddy.ai. Your feedback helps refine your AI coach's approach and allows you to customize its coaching style based on your needs and preferences. Over time, you can create a coach that is fully adapted to your way of learning and growing.",
  },
  {
    id: 7,
    title: "How does LifeBuddy.ai handle confidentiality?",
    description:
      "LifeBuddy.ai takes client confidentiality very seriously. All session records are strictly confidential and only accessible to certified experts involved in your coaching journey. You also have complete control over your data sharing preferences.",
  },
  {
    id: 8,
    title: "Can I switch coaches if I'm not happy?",
    description:
      "Yes. We understand that rapport and continuity are important. You can switch coaches after 4 sessions. All notes and records can be transferred to the new coach, or you can choose to start fresh.",
    text: "We hope this answers your questions about LifeBuddy.ai! If you have any further questions, please don't hesitate to contact us.",
  },
]
