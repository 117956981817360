import { Link } from "react-router-dom"
import BackImg from "../../../assets/back.svg"
import Logo from "../../../assets/logo.png"
import { FC } from "react"
import { MobileHeaderS, TitleBox, LogoImg, BackImgEl } from "./styles"
import { Props } from "./types"

export const MobileHeader: FC<Props> = ({ title, isHomePage, previousPath }) => {
  return (
    <MobileHeaderS $isHomePage={!!isHomePage}>
      {previousPath ? (
        <Link to={previousPath}>
          <BackImgEl src={BackImg} alt='back' />
        </Link>
      ) : (
        <div />
      )}
      <TitleBox><span>{title}</span></TitleBox>
      <LogoImg src={Logo} alt='logo' />
    </MobileHeaderS>
  )
}
