import { IIndent } from "../components"

export const termIndents: IIndent[] = [
  {
    id: 1,
    title: "1. Introduction",
    description:
      "Welcome to the mobile application (the 'App'). These Terms of Service (the 'Terms') govern your access to and use of the App, which is provided by OPEN KEYS LIMITED (the 'Developer'). Please read these Terms carefully before using the App. By using the App, you agree to be bound by these Terms.",
  },
  {
    id: 2,
    title: "2. Agreement",
    description:
      "These Terms constitute a legally binding agreement between you and the Developer. By using the App, you represent and warrant that you are of legal age to enter into this agreement and that you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the App.",
  },
  {
    id: 3,
    title: "3. Use of the App",
    description:
      "The App is provided to you for your personal, non-commercial use only. You may use the App to access educational and entertainment content provided by the Developer. You may not use the App for any other purpose without the prior written consent of the Developer.",
  },
  {
    id: 4,
    title: "4. Accounts",
    description:
      "To use certain features of the App, you may need to create an account. You agree to provide accurate and complete information when creating your account and to update your information as necessary. You are responsible for maintaining the confidentiality of your account information.",
  },
  {
    id: 5,
    title: "5. Content",
    description:
      "The Developer reserves the right to modify or remove any content from the App at any time. The Developer does not guarantee that the App will always be available or that the content will be accurate or up-to-date.",
  },
  {
    id: 6,
    title: "6. Intellectual Property",
    description:
      "The App and its content are the property of the Developer and its licensors. All rights not expressly granted in these Terms are reserved to the Developer and its licensors. You may not copy, modify, distribute, transmit, display, perform, reproduce, or create derivative works from the App or its content without the prior written consent of the Developer.",
  },
  {
    id: 7,
    title: "7. Linking",
    description:
      "You may not link to the App without the prior written consent of the Developer. If you wish to link to the App, please contact the Developer at info@lifebuddy.ai",
  },
  {
    id: 8,
    title: "8. Disclaimer of Warranties",
    description:
      "The Developer makes no warranties of any kind, express or implied, regarding the App or its content. The Developer disclaims all liability for any damages arising from or in connection with your use of the App or its content.",
  },
  {
    id: 9,
    title: "9. Limitation of Liability",
    description:
      "In no event shall the Developer be liable to you for any damages, including, without limitation, direct, indirect, incidental, consequential, special, or punitive damages, arising out of or in connection with your use of the App or its content.",
  },
  {
    id: 10,
    title: "10. Indemnification",
    description:
      "You agree to indemnify and hold harmless the Developer from and against all claims, damages, losses, liabilities, costs, and expenses (including attorneys' fees) arising out of or in connection with your use of the App or its content.",
  },
  {
    id: 11,
    title: "11. Termination",
    description:
      "The Developer may terminate your access to the App at any time for any reason, with or without notice. You may terminate your use of the App at any time. Upon termination, your right to use the App will immediately cease.",
  },
  {
    id: 12,
    title: "12. Governing Law",
    description:
      "These Terms shall be governed by and construed in accordance with the laws of Hong Kong.",
  },
  {
    id: 13,
    title: "13. Entire Agreement",
    description:
      "These Terms constitute the entire agreement between you and the Developer regarding your use of the App. These Terms supersede and replace any prior or contemporaneous communications, representations, or agreements, whether oral or written, between you and the Developer regarding the App.",
  },
  {
    id: 14,
    title: "14. Severability",
    description:
      "If any provision of these Terms is held to be invalid or unenforceable, such provision will be struck from these Terms and the remaining provisions will remain in full force and effect.",
  },
  {
    id: 15,
    title: "15. Notices",
    description:
      "All notices and other communications hereunder shall be in writing and shall be deemed to have been duly given when delivered in person, upon the first business day following deposit in the United States mail, postage prepaid, certified or registered, return receipt requested, addressed to the Developer: info@lifebuddy.ai or to such other address as either party may designate in writing from time to time.",
  },
  {
    id: 16,
    title: "16. Waiver",
    description:
      "No waiver of any provision of these Terms shall be effective unless in writing and signed by both parties.",
  },
  {
    id: 17,
    title: "17. Counterparts",
    description:
      "These Terms may be executed in one or more counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same instrument.",
  },
  {
    id: 18,
    title: "18. Amendments",
    description: "The Developer may amend these Terms",
  },
]
