import disabled from "../assets/radio-disabled.svg"
import active from "../assets/radio-active.svg"
import { Gender } from "../pages/PersonalInfo/types"

export const genders: Gender[] = [
  {
    type: "female",
    name: "Female",
    imgActive: active,
    imgDisabled: disabled,
  },
  {
    type: "male",
    name: "Male",
    imgActive: active,
    imgDisabled: disabled,
  },
  {
    type: "undefined",
    name: "I don't want to say",
    imgActive: active,
    imgDisabled: disabled,
  },
];
