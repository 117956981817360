import styled from "styled-components"

export const WrapperS = styled.div<{$gap?: string}>`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${({$gap}) => $gap ? $gap : "10px"};
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: black;
`

