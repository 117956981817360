import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"
import { FacebookAuthProvider, GoogleAuthProvider, getAuth } from "firebase/auth"
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCLZdLyITRRlWYvpR7VDcA-qvSmHsURIFc",
  authDomain: "life-buddy-ai.firebaseapp.com",
  projectId: "life-buddy-ai",
  storageBucket: "life-buddy-ai.appspot.com",
  messagingSenderId: "183478268693",
  appId: "1:183478268693:web:7ca4836c372e3d8fbf6f1c",
  measurementId: "G-Y0LJQ6GNHR",
}

export const app = initializeApp(firebaseConfig)

export const storage = getStorage(app);
export const auth = getAuth()
export const analytics = getAnalytics(app);
export const googleAuthProvider = new GoogleAuthProvider()
export const facebookAuthProvider = new FacebookAuthProvider()

export default app;

