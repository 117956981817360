import { FC } from "react"
import { ButtonS } from "./styles"
import { Props } from "./types"

export const Button: FC<Props> = ({
  size = "medium",
  onClick,
  children,
  variant = "primary",
  disabled,
  type = "button",
}) => {
  return (
    <ButtonS
      onClick={onClick}
      disabled={disabled}
      type={type}
      $disabled={!!disabled}
      $variant={variant}
      $size={size}
    >
      {children}
    </ButtonS>
  )
}
