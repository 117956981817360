import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Coach } from "./type"
import { coachs } from "../../../data"
import { Pagination } from "./Pagination"
import { VideoContainer, VideoS, LinkCustom } from "./styles"
import { ContextValue, RootContext } from "../../../pages/Layout/Layout"
import { logEvent } from "firebase/analytics"
import { analytics } from "../../../config"
import { Carousel } from "react-responsive-carousel"
import ArrowImg from "../../../assets/arrow-right-simple.svg"
import SliderSlick from "react-slick"
import styled from "styled-components"
import "slick-carousel/slick/slick.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./Slider.style.css"

const SlidersContainerS = styled.div`
  display: flex;
  padding: 25px 0;
  max-width: calc(540px + 150px);
`

const CarouselS = styled(Carousel)`
  & .carousel {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`

const SlideS = styled.div`
  min-height: 500px;
  height: 100%;
  padding: 0 20px;

  @media (max-width: 768px) {
    min-height: 300px;
  }

  @media (max-width: 350px) {
    min-height: auto;
  }
`

export const Slider = () => {
  const paginationSliderRef = useRef<SliderSlick>(null)
  const [currentSlide, setCurrentSlide] = useState(coachs[0].id)
  const videoRef = useRef<HTMLDivElement>(null)
  const [isVideoMuted, setIsVideoMuted] = useState(true)
  const { dispatch } = useContext(RootContext) as ContextValue
  const navigate = useNavigate()

  const iterateVideoElements = (videoHandler: (video: HTMLVideoElement) => void) => {
    const wrapper = videoRef.current?.querySelectorAll("video") as unknown as HTMLVideoElement[]

    if (wrapper) {
      for (let i = 1; i < 6; i++) {
        videoHandler(wrapper[i])
      }
    }
  }

  useEffect(() => {
    const handlerWrapper = (video: HTMLVideoElement) => {
      const volumeChangeHandler = () => {
        setIsVideoMuted(video.muted)
      }
      return volumeChangeHandler
    }

    iterateVideoElements((video) => {
      video.addEventListener("volumechange", handlerWrapper(video))
    })

    return () => {
      iterateVideoElements((video) => {
        video.removeEventListener("volumechange", handlerWrapper(video))
      })
    }
  }, [])

  useEffect(() => {
    iterateVideoElements((video) => {
      if (video.nodeName === "VIDEO") {
        if (+video.id === currentSlide) {
          video.play()
        } else {
          video.currentTime = 0
          video.pause()
        }
      }
    })
  }, [currentSlide])

  return (
    <>
      <SlidersContainerS ref={videoRef}>
        <CarouselS
          selectedItem={currentSlide}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          onChange={(index) => {
            setCurrentSlide(index)
            paginationSliderRef.current?.slickGoTo(index)
          }}
          emulateTouch
          infiniteLoop
          renderArrowNext={(handler) => <div className='swiper-button-next' onClick={handler} />}
          renderArrowPrev={(handler) => <div className={"swiper-button-prev"} onClick={handler} />}
        >
          {coachs.map((coach: Coach, idx) => (
            <SlideS key={coach.id}>
              <VideoContainer id={idx.toString()}>
                <VideoS id={coach.id.toString()} controls playsInline muted={isVideoMuted}>
                  <source src={coach.videoURL} type='video/mp4' />
                </VideoS>
              </VideoContainer>
            </SlideS>
          ))}
        </CarouselS>
      </SlidersContainerS>
      <Pagination
        ref={paginationSliderRef}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
      />
      <div className='selection'>
        {coachs[currentSlide].status ? (
          <button
            id='select-btn'
            onClick={() => {
              logEvent(analytics, "select_coach", {
                name: coachs[currentSlide].coach,
              })
              dispatch({ type: "SET_COACH", payload: coachs[currentSlide].coach })
              navigate("/personal-info")
            }}
          >
            Select {coachs[currentSlide].fullname.split(" ")[0]}
          </button>
        ) : (
          <button id='blocked-btn'>Coming Soon</button>
        )}
      </div>
      <LinkCustom to='/how-it-works'>
        <div>How it works?</div>
        <img src={ArrowImg} alt='arrow' />
      </LinkCustom>
    </>
  )
}
