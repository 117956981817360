import styled from "styled-components"

export const HeaderS = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  position: relative;
  padding: 10px 0;
  min-height: 80px;

  @media (max-width: 1200px) {
    padding: 8px 20px;
  }

  @media (max-width: 576px) {
    display: none;
  }
`

export const HeaderWrapS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 750px !important; 
  position: relative;
  padding: 0;  

  @media (max-width: 1200px) {
    padding: 0;
  }
`

export const HeaderContentS = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Logo = styled.img`
  left: 0;
  top: 25px;
  z-index: 1;
  margin-right: 10px;
  position: static;
  width: 60px;
`
