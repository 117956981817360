import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  *,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: "Work Sans", sans-serif;
}

#root {
  background-size: cover;
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit
}

`
