import { Link } from "react-router-dom"
import BackImg from "../../../assets/back.svg"
import { FC } from "react"
import { AdditionalHeaderS } from "./styles"
import { Props } from "./types"

export const AdditionalHeader: FC<Props> = ({ title, previousPath }) => {
  return (
    <AdditionalHeaderS>
      <Link to={previousPath}>
        <img src={BackImg} alt='back' />
      </Link>
      {title && <div>{title}</div>}
      <div />
    </AdditionalHeaderS>
  )
}
