import styled from "styled-components"

export const ContentS = styled.div`
  padding: 40px 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  & h1 {
    font-weight: 600;
    font-size: 40px;
    line-height: 56px;
  }
    
  @media (max-width: 576px) {
      & h1 {
          font-size: 32px;
          line-height: 40px;
      }
  }
`