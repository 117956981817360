import styled from "styled-components";

export const MobileHeaderS = styled.div<{ $isHomePage: boolean }>`
  width: 100%;
  display: none;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: ${({ $isHomePage }) => ($isHomePage ? "0 20px" : "0")};
  margin-top: ${({ $isHomePage }) => ($isHomePage ? "15px" : "0")};
  margin-bottom: 15px;
  
  @media (max-width: 576px) {
    display: flex;
  }
`

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  height: 52px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #14141b;
`
export const LogoImg = styled.img`
  height: 52px;
  margin-left: 5px;
`

export const BackImgEl = styled.img`
  margin-right: 10px;
`