import styled from "styled-components";

export const AdditionalHeaderS = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #14141b;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
`