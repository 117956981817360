import { FC, ReactNode } from "react"
import { ContentS } from "./styles"

interface Props {
  children: ReactNode
}

export const Content: FC<Props> = ({ children }) => {
  return <ContentS>{children}</ContentS>
}
